<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
     <v-card elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">Contas</h3></v-card-title
        >
        <div class="card-body">
          <b-table
            :fields="['cliente', 'tipo', 'status', 'saldo', 'acoes']"
            :items="lista_contas"
            :per-page="perPage"
            :current-page="currentPage"
            id="filiais-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
              <template #cell(saldo)="data">
              <span>
                {{ data.item.saldo | formatMoney }}
              </span>
            </template>
            <template #cell(status)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 1,
                  'label-light-danger': data.item.status === 0,
                  'label-light-warning': data.item.status === 2,
                }"
              >
                {{ data.item.status === 1 ? "Ativo" : "Inativo" }}
              </span>
            </template>
            <template #cell(tipo)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.tipo === 1,
                  'label-light-info': data.item.tipo === 2,
                }"
              >
                {{ data.item.status === 1 ? "PF" : "PJ" }}
              </span>
            </template>
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                     v-show="lista_permissoes_filial[btn.permisao]"
                      fab
                      outlined
                      class="mr-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_contas.length"
            :per-page="perPage"
            aria-controls="filiais-table"
          >
          </b-pagination>
        </div>
     </v-card>
    </div>
  </div>

</template>



<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [moneyFormat],

  data() {
    return {
      lista_btn: [
           {
          permisao:"r_Contas",
          button: "entrar",
          title: "Gerenciar Conta",
          color: "warning",
          icon: "fa fa-arrow-right text-warning",
        },
        {
          permisao:"u_Contas",
          button: "editar",
          title: "Editar registro",
          color: "primary",
          icon: "far fa-edit text-primary",
        },
        {
          permisao:"lock_Contas",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-unlock-alt text-danger",
        },
      ],
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contas" }]);
  },
  computed: {
    lista_contas() {
      return this.$store.state.contas.lista_contas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_contas();
  },
  methods: {
    acoes(item, tipo) {
      if (tipo == "editar") this.atualizar(item);
      if (tipo == "exclusao") this.confirm(item);
      if(tipo == "entrar") this.gerenciarConta(item);
    },
    async listar_contas() {
      await this.$store.dispatch("contas/listar_contas");
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({ name: "createContas" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    gerenciarConta(value){
      this.$router.push({ name: "gerenciarConta" });
      this.$store.dispatch("contas/set_conta", value);


    }
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>